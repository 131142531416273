<template>
  <div>
    <div>Детализация (Parts)</div>

    <el-table
      v-loading="loading"
      size="mini"
      empty-text="Нет партов"
      :data="parts"
    >
      <el-table-column prop="id" label="ID Parts" width="70"></el-table-column>
      <el-table-column prop="part_1" label="Уровень 1 (канал, направление)">
        <template v-slot="scope">
          <el-input size="mini" v-model="scope.row.part_1" :maxlength="255"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="part_2" label="Уровень 2 (площадка, регион)">
        <template v-slot="scope">
          <el-input size="mini" v-model="scope.row.part_2" :maxlength="255"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="part_3" label="Уровень 3 (сегмент, продукт)">
        <template v-slot="scope">
          <el-input size="mini" v-model="scope.row.part_3" :maxlength="255"></el-input>
        </template>
      </el-table-column>

      <el-table-column v-if="scaleType==='progressive'" prop="plan" label="План" width="130">
        <template v-slot="scope">
          <el-input size="mini" v-model="scope.row.plan"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="scaleType==='progressive'" prop="fact" label="Фактическое значение" width="130">
        <template v-slot="scope">
          <el-input size="mini" v-model="scope.row.fact"></el-input>
        </template>
      </el-table-column>


      <el-table-column v-if="scaleType==='design'" prop="pay_100" label="Ключевые результаты выполнения КПЭ на 100%" width="230">
        <template v-slot="scope">
          <el-input type="textarea" :autosize="{ minRows: 2}" size="mini" v-model="scope.row.pay_100"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="scaleType==='design'" prop="pay_75" label="Ключевые результаты выполнения КПЭ на 75%" width="230">
        <template v-slot="scope">
          <el-input type="textarea" :autosize="{ minRows: 2}" size="mini" v-model="scope.row.pay_75"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="scaleType==='design'" prop="pay_50" label="Ключевые результаты выполнения КПЭ на 50%" width="230">
        <template v-slot="scope">
          <el-input type="textarea" :autosize="{ minRows: 2}" size="mini" v-model="scope.row.pay_50"></el-input>
        </template>
      </el-table-column>
      <el-table-column v-if="scaleType==='design'" prop="fact" label="Фактическое значение" width="130">
        <template v-slot="scope">
          <el-select
            v-model="scope.row.fact"
            style="width:100%"
          >
            <el-option
              v-for="scaleScope in designScaleScopes"
              :key="scaleScope.id"
              :label="scaleScope.target"
              :value="scaleScope.id"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column
        :width="50"
        align="center"
      >
        <template v-slot="scope">
          <el-popconfirm
            v-show="parts.length > 1"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить парт?"
            @confirm="deletePart(scope.row, scope.$index)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="far fa-trash-alt"
              :loading="loading"
            >
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      size="mini"
      type="text"
      icon="fas fa-plus"
      @click="addRow"
    >Добавить</el-button>
  </div>
</template>

<script>

import {variables} from "@/componentsCompany/cherkizovo/variables";
import requestSender from "@/api/base/requestSender";

export default {
  name: "library-edit-parts-form",

  props: {
    scaleId: {type: Number|null, required: true},
    parts: {type: Array|null, required: true},
    scaleType: {type: String, required: true},
    designScaleScopes: {type: Array},
    isClone: {type: Boolean},
  },
  computed: {},
  watch: {
    'scaleId': function (newVal) {
      this.loadParts();
    },
    'isClone': function (newVal) {
      this.loadParts();
    },
  },
  mounted() {},

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    loadParts(){
      if( !this.scaleId ){
        this.$emit('update:parts', [{
          'part_1': variables.scalePartDefaultName,
          'part_2': variables.scalePartDefaultName,
          'part_3': variables.scalePartDefaultName,
        }]);
        return;
      }
      this.loading = true;
      requestSender('get', 'scale/get-parts-for-scale', {
        id: this.scaleId,
      })
        .then(data => {
          if( this.isClone ){
            this.$emit( 'update:parts', data.scale_parts.map(el => {return {...el, id: null, scale_id: null}}) );
          } else {
            this.$emit('update:parts', data.scale_parts);
          }
        })
        .finally(()=>{
          this.loading = false;
        })
    },

    addRow(){
      this.parts.push({});
    },
    deletePart(part, rowIndex){
      if( part.id ){
        this.$emit('update:parts', this.parts.filter(p => p.id !== part.id));
      } else {
        this.parts.splice(rowIndex, 1);
        this.$emit('update:parts', this.parts);
      }
    },


  }
}
</script>

<style>

</style>